import OCPP from '@electrifly/ocpp';
import * as OCPI from '@electrifly/ocpi';
import { TransactionPrice } from '../tariff/TransactionPrice';
import { BusinessHours } from './BusinessHours';
import { ConnectorTariff } from '../tariff/ConnectorTariff';

const REMOTE_START_AVAILABLE_STATUSES: (OCPP.V15.ChargePointStatus | OCPP.V16.ChargePointStatus)[] = [
    'Available',
    'Preparing',
];

export enum ChargePointState {
    CREATED = 'CREATED',
    PRODUCTION = 'PRODUCTION',
    SERVICE = 'SERVICE',
    REPAIR = 'REPAIR',
    SUSPENDED = 'SUSPENDED',
    RETIRED = 'RETIRED',
}

export type OCPPStatusWrapper = {
    status: OCPP.V15.ChargePointStatus | OCPP.V16.ChargePointStatus;
    errorCode: OCPP.V15.ChargePointErrorCode | OCPP.V16.ChargePointErrorCode;
    info?: string;
    timestamp?: string | number;
    vendorId?: string;
    vendorErrorCode?: string;
};

export interface EVSE {
    _id: string;
    chargePoint: string;
    connectors: Connector[];
    deleted: boolean;
    lastUpdated: string;
}

export interface EVSEDeleted {
    _id: string;
    chargePoint: string;
    deleted: true;
}

export const EVSE = {
    isDeleted: (evse: EVSE | EVSEDeleted): evse is EVSEDeleted => {
        return evse.deleted === true;
    },
};

export interface Connector {
    _id: string;
    evse: string;

    power: number;
    standard: OCPI.V221.Types.ConnectorType;
    format: OCPI.V221.Types.ConnectorFormat;
    powerType: OCPI.V221.Types.PowerType;

    tariffs: ConnectorTariff[];
    isTurnedOff: boolean;

    deleted: boolean;

    statusMessage: OCPPStatusWrapper;
    lastUpdated: string;
}

export interface ConnectorDeleted {
    _id: string;
    evse: string;
    deleted: true;
}

export const Connector = {
    isDeleted: (connector: Connector | ConnectorDeleted): connector is ConnectorDeleted => {
        return connector.deleted === true;
    },
};

export enum ChargePointConnectionStatus {
    ONLINE = 'Online',
    OFFLINE = 'Offline',
}

type ChargePointOCPPV15 = {
    connectionStatus: ChargePointConnectionStatus;
    protocol: OCPP.Version;
    statusMessages: OCPP.V15.StatusNotificationRequest[];
    configuration: OCPP.V15.KeyValue[];
};

type ChargePointOCPPV16 = {
    connectionStatus: ChargePointConnectionStatus;
    protocol: OCPP.Version;
    statusMessages: OCPP.V16.StatusNotificationRequest[];
    configuration: OCPP.V16.KeyValue[];
};

export type ChargePointOCPP = ChargePointOCPPV15 | ChargePointOCPPV16;

export type ChargePoint = {
    _id: string;
    location: string;

    chargeBoxIdentity: string;
    roamingIdentity: string;
    physicalReference: string;

    state: ChargePointState;
    is_time_transform?: boolean;
    publish: boolean;
    freevend: boolean;
    ignorePingPong?: boolean;

    vendor: string;
    model: string;
    serialNumber: string;
    simNumber?: string;

    allowOnlyTags?: string[];

    authorizationRequired: boolean;

    // //New ui
    ocpp: ChargePointOCPP;
    evses: EVSE[];

    statusMessage: OCPPStatusWrapper;
    emergency?: boolean;

    comment?: string;

    deleted: boolean;
    lastUpdated: string;
};

export interface ChargePointDeleted {
    _id: string;
    location: string;
    deleted: true;
}

export const ChargePoint = {
    isDeleted: (chargePoint: ChargePoint | ChargePointDeleted): chargePoint is ChargePointDeleted => {
        return chargePoint.deleted === true;
    },

    isOnline: (chargePoint: ChargePoint) => {
        return (
            chargePoint && chargePoint.ocpp && chargePoint.ocpp.connectionStatus === ChargePointConnectionStatus.ONLINE
        );
    },

    isOffline: (chargePoint: ChargePoint) => {
        return !ChargePoint.isOnline(chargePoint);
    },

    canRemoteStart: (chargePoint: ChargePoint, connector: Connector): boolean => {
        return (
            ChargePoint.isOnline(chargePoint) &&
            REMOTE_START_AVAILABLE_STATUSES.includes(connector.statusMessage.status)
        );
    },
};

export const ChargeLocationType = ['stationary', 'mobile'] as const;
export type ChargeLocationType = typeof ChargeLocationType[number];

export const InfrastructureType = ['internal', 'external'] as const;
export type InfrastructureType = typeof InfrastructureType[number];

export interface ChargeLocation {
    _id: string;
    type: ChargeLocationType;
    name: string;
    operator: string;
    holder: string;
    chargePoints: ChargePoint[];
    address: string;
    city?: string;
    region?: string;
    directions?: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    businessHours: BusinessHours;
    parkingType: OCPI.V221.Types.ParkingType;
    parkingEVOnly: boolean;
    parkingCustomersOnly: boolean;
    facilities: OCPI.V221.Types.Facility[];
    amenities: string[];
    timezone: string;
    deleted: boolean;
    publish: boolean;
    comment?: string;
    mobileLocationId?: string;
    lastUpdated: string;
    infoMessage?: string;
    allowClientIds: string[]
    maxKwh?: number;
}

export interface ChargeLocationDeleted {
    _id: string;
    deleted: true;
}

export const ChargeLocation = {
    isDeleted: (chargeLocation: ChargeLocation | ChargeLocationDeleted): chargeLocation is ChargeLocationDeleted => {
        return chargeLocation.deleted === true;
    },
};
